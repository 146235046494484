<template>
	<div class="cbox">
		<div class="headbar">
			<div style="float: right;">

				<el-input clearable size="small" placeholder="请输入内容" v-model="queryForm.keyword"
					style="width:300px;margin-right:20px">
					<el-select v-model="queryForm.ktype" slot="prepend" placeholder="请选择" style="width:100px">
						<el-option label="学生姓名" value="stu_name"></el-option>
						<el-option label="手机号" value="phone"></el-option>
						<el-option label="身份证号" value="id_number"></el-option>
					</el-select>

				</el-input>

				<el-input clearable size="small" placeholder="请输入学校名称" v-model="queryForm.school"
					style="width:200px;margin-right:20px">
				</el-input>

				<el-select clearable v-model="queryForm.grade_name" placeholder="选择年级" size="small"
					style="width:120px;margin-right:20px">
					<el-option label="小班" value="小班"></el-option>
					<el-option label="中班" value="中班"></el-option>
					<el-option label="大班" value="大班"></el-option>
					<el-option label="一年级" value="一年级"></el-option>
					<el-option label="二年级" value="二年级"></el-option>
					<el-option label="三年级" value="三年级"></el-option>
					<el-option label="四年级" value="四年级"></el-option>
					<el-option label="五年级" value="五年级"></el-option>
					<el-option label="六年级" value="六年级"></el-option>
					<el-option label="高一" value="高一"></el-option>
					<el-option label="高二" value="高二"></el-option>
					<el-option label="高三" value="高三"></el-option>
				</el-select>
				<el-select clearable v-model="queryForm.class_name" placeholder="选择班级" size="small"
					style="width:120px;margin-right:20px">

					<el-option label="1班" value="1班"></el-option>
					<el-option label="2班" value="2班"></el-option>
					<el-option label="3班" value="3班"></el-option>
					<el-option label="4班" value="4班"></el-option>
					<el-option label="5班" value="5班"></el-option>
					<el-option label="6班" value="6班"></el-option>
					<el-option label="7班" value="7班"></el-option>
					<el-option label="8班" value="8班"></el-option>
					<el-option label="9班" value="9班"></el-option>
					<el-option label="10班" value="10班"></el-option>
					<el-option label="11班" value="11班"></el-option>
					<el-option label="12班" value="12班"></el-option>
					<el-option label="13班" value="13班"></el-option>
					<el-option label="14班" value="14班"></el-option>
					<el-option label="15班" value="15班"></el-option>
					<el-option label="16班" value="16班"></el-option>
					<el-option label="17班" value="17班"></el-option>
					<el-option label="18班" value="18班"></el-option>
					<el-option label="19班" value="19班"></el-option>
					<el-option label="20班" value="20班"></el-option>
				</el-select>

				<el-select clearable v-model="queryForm.status" placeholder="保单状态" size="small"
					style="width:120px;margin-right:20px">
					<el-option label="未处理" :value="0"></el-option>
					<el-option label="已绑定" value="1"></el-option>
					<el-option label="失败-无保单" value="2"></el-option>
					<el-option label="失败-已被绑" value="3"></el-option>
					

				</el-select>

				<el-button size="small" type="primary" icon="el-icon-search" @click="search">搜索</el-button>

				<el-button size="small" type="text" icon="el-icon-download" @click="exportOrders">导出</el-button>
			</div>
			<div style="padding-top: 14px;">
				<el-breadcrumb>
					<el-breadcrumb-item>保单管理</el-breadcrumb-item>
					<el-breadcrumb-item>用户申请绑定</el-breadcrumb-item>

				</el-breadcrumb>
			</div>

		</div>
		<div class="bcontent">
			<el-table stripe :data="DataList" style="width: 100%;margin-bottom: 20px; color: #000;" border size="mini">
				<el-table-column type="index" label="编号" width="80">
				</el-table-column>
				<el-table-column prop="stu_name" label="学生姓名" width="120">
					<template slot-scope="scope">
						<span style="color:palevioletred" v-if="scope.row.gender == 2">{{ scope.row.stu_name }}</span>
						<span style="color:skyblue" v-if="scope.row.gender == 1">{{ scope.row.stu_name }}</span>

					</template>
				</el-table-column>
				<el-table-column prop="id_number" label="学生身份证号" width="160">

				</el-table-column>
				<el-table-column prop="school_name" label="学校" min-width="120">

				</el-table-column>
				<el-table-column prop="grade_name" label="年级" width="180">

				</el-table-column>
				<el-table-column prop="class_name" label="班级" width="100">

				</el-table-column>
				<el-table-column prop="parent_name" label="家长姓名" width="120">

				</el-table-column>
				<el-table-column prop="phone" label="家长手机号" width="120">

				</el-table-column>
				



				<el-table-column prop="status" label="状态" width="200">
					<template slot-scope="scope">
						<div v-if="scope.row.status == 1"><span style="color:#1a50f5">已绑定</span></div>
						
							<div v-if="(scope.row.status == 0)"><span style="color:orangered">未绑定</span></div>
						
							<div v-if="scope.row.status == 2"><span style="color:gray">绑定失败，没有在投保名单找到该学生</span></div>
							<div v-if="(scope.row.status == 3)"><span style="color:red">绑定失败，已经有人绑定过该学生</span></div>

					</template>
				</el-table-column>


				<el-table-column fixed="right" label="操作" width="250">
					<template slot-scope="scope">

						<el-button type="text" v-if="scope.row.status==0" @click="viewInfo(scope.row)">确认已购保险</el-button>
						<el-button type="text" v-if="scope.row.status==0" @click="rejectForm(scope.row)">未购买保险</el-button>

					</template>
				</el-table-column>
			</el-table>

			<div style="height: 20px;">

			</div>


		</div>
		<div
			style="position: absolute; bottom: 0; height: 40px; width: 100%; text-align: right; background-color: #fff; z-index: 20;">
			<el-pagination @current-change="paginate" :current-page="Page.current_page" :page-size="Page.per_page"
				:total="Page.count" layout="total, prev, pager, next">
			</el-pagination>
		</div>


		<el-dialog title="保单详情" :visible.sync="dialogVisible" :close-on-click-modal="false" width="1000px">
			<div style="padding-right: 40px;">
				<el-form v-if="EditItem" :model="EditItem" ref="EditItem" label-width="120px">

					<el-row style="padding-top: 20px;">
						<el-col :span="12">
							<el-form-item label="学生姓名">
								<el-input readonly v-model="EditItem.stu_name"></el-input>
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="性别">
								<el-input readonly :value="EditItem.gender == 1 ? '男' : '女'"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="身份证号">
								<el-input readonly v-model="EditItem.id_number"></el-input>
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="出生日期">
								<el-input readonly v-model="EditItem.birthday"></el-input>
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="所在学校">
								<el-input readonly v-model="EditItem.school_name"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="年级">
								<el-input readonly v-model="EditItem.grade_name"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="班级">
								<el-input readonly v-model="EditItem.class_name"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="家长姓名">
								<el-input readonly v-model="EditItem.parent_name"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="家长手机号">
								<el-input readonly v-model="EditItem.phone"></el-input>
							</el-form-item>
						</el-col>
						
						<el-col :span="12">
							<el-form-item label="保险有效期" required>
								
								<el-select @change="changeDate" v-model="EditItem.youxiaoqi" style="width:100%">
									<el-option :label="item.value" :value="item.value" v-for="(item,idx) in youxiaoqi" :key="idx"></el-option>
								</el-select>
							
							
							</el-form-item>
						</el-col>
						
					</el-row>


				</el-form>
			</div>
			<el-row>
				<el-col :span="22" style="text-align: right;">

					<el-button type="" @click="dialogVisible = false">关闭</el-button>
					<el-button  @click="saveNewBaodan" type="primary">确认保单</el-button>
				</el-col>
			</el-row>
		</el-dialog>


	</div>
</template>

<script>

export default {
	components: {

	},
	data() {
		return {
			temphead: "../../../static/temphead.jpg",
			queryForm: {
				ktype: "stu_name",
				keyword: "",
				grade_name: "",
				class_name: "",
				school: "",
				status: ""

			},
			activeName: "first",
			DataList: [],
			dialogVisible: false,
			importShow: false,
			showTongji: false,
			Page: {
				current_page: 1,
				next_page: 2,
				pre_page: 20,
				per_page: 20,
				total_page: 0,
				count: 0
			},
			product_catalog: [],
			userinfo: {},
			datainfo: [],
			read_teacher: [],
			read_teacher_num: 0,
			read_no_teacher: [],
			read_no_teacher_num: 0,
			read_student: [],
			detailTitle: "",
			EditItem: null,
			//按钮权限
			auth: {
				edit: false,
				delete: false
			},
			TypeList: [],
			DeptData: [],
			showPhone: false,
			canAdd: false,
			canEdit: false,
			youxiaoqi:[]

		}
	},

	mounted() {
		this.search()
		let year = new Date().getFullYear();
		this.youxiaoqi=[{value:year+"-09-01"+" 至 "+(year+1)+"-09-01"},{value:(year-1)+"-09-01"+" 至 "+(year)+"-09-01"}]
	},
	methods: {
		changeDate(er){
			
			this.$nextTick(e=>{this.$forceUpdate()})
		},

		paginate(val) {
			this.Page.page = val;
			this.query(val)
		},
		search: function () {
			this.query(1);
		},
		query(page) {
			this.queryForm.page = page
			this.$http.post('/api/bind_apply_list', this.queryForm).then(res => {
				this.DataList = res.data.data;
				this.Page = res.data.page
			});
		},
		update(item) {

			sessionStorage.setItem("product", JSON.stringify(item))

			this.$router.push("/proedit?" + item.id)
		},
		add() {
			this.$router.push("/notifyedit")
		},
		


		viewInfo(item) {
			if(!item.youxiaoqi){
				item.youxiaoqi=""
			}
			console.log(item)
			this.EditItem = item
			this.dialogVisible = true
			// this.$http.post("/api/article_info", {
			// 	id: item.id,

			// }).then(res => {

			// })
		},
		rejectForm(e){
			this.$confirm('确认该学生未购买保险?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http.post("/api/bind_new_bderror", {
					id: e.id
				}).then(res => {
					this.$message({
						type: 'success',
						message: '保存成功'
					});

					this.query(this.Page.page);
				})

			}).catch(() => {

			});
		},

		editItem(item) {
			this.$router.push("/notifyedit?id=" + item.id)
		},
		setTop(item) {
			this.$http.post("/api/article_top", {
				id: item.id,
				top: item.top ? 0 : 1
			}).then(res => {
				this.$message({
					type: 'success',
					message: '置顶成功'
				});

				this.query();
			})
		},

		closePhone() {
			this.showPhone = false
			this.showTongji = false
		},
		viewDetail(e) {
			this.EditItem = e
			this.showTongji = true
		},
		handleXlsUpload(e) {//教师导入

			this.$http.post("/api/baodan_import_students", { url: e.src }).then((res) => {
				this.$message.success('导入成功')
				this.importShow = false
				this.query();
			});
		},
		exportOrders() {
			this.$http.post('/api/baodan_list_export', this.queryForm).then(res => {
				if (res.data.url) {
					window.open(res.data.url, "_blank")
				}
			});
		},
		saveNewBaodan(){
			if(!this.EditItem.youxiaoqi){
				this.$message.error('请选择保单有效期')
				return
			}
			let data = this.EditItem;
			data.start_time = this.EditItem.youxiaoqi.split('至')[0].trim()
			data.end_time = this.EditItem.youxiaoqi.split('至')[1].trim()
			this.$http.post('/api/bind_new_bd', data).then(res => {
				this.$message.success('保单创建成功！')
				this.dialogVisible = false
			});

			setTimeout(()=>{

				this.query(this.Page.page)
			},2000)



		}




	}
}
</script>
<style scoped type="text/less" lang="less">
.pageContainer {
	height: 100%;
}

.product-status {
	padding-bottom: 10px;
	border-bottom: 5px solid #e8e8e8;
}

.arttile {
	font-size: 18px;
	color: #333333;
	padding: 20px 0;
}

.typeitem {
	display: inline-block;
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 0px 10px;
}

.contentcontent {
	padding: 20px 0;
}

.attaches {
	padding: 20px 0;
}

.attitem {
	color: #0785D1;
	cursor: pointer;
}

.useritem {
	width: 200px;
	display: inline-block;
	vertical-align: top;
	margin-bottom: 5px;
	margin-right: 5px;
	border: 1px solid #f6f6f6;
	padding: 5px;
}

.useritem image {
	vertical-align: middle;
}
</style>
